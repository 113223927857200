import { App } from '../../../shared/js/register.js';
import { start } from '../../../shared/js/init.js';

import translations from './translations.json';
import '../../../shared/js/phones.js';

document.addEventListener("DOMContentLoaded", function () {
    window.Form = new App({

        noResults: "\u004e\u006f\u0020\u006d\u0061\u0074\u0063\u0068\u0065\u0073\u0020\u0066\u006f\u0075\u006e\u0064",
        messages: {
            required: '\u0046\u0069\u006c\u006c\u0020\u0069\u006e\u0020\u0074\u0068\u0065\u0020\u0066\u0069\u0065\u006c\u0064',
            phone: {
                incorrect: '\u0049\u006e\u0063\u006f\u0072\u0072\u0065\u0063\u0074\u0020\u0070\u0068\u006f\u006e\u0065\u0020\u006e\u0075\u006d\u0062\u0065\u0072'
            },
            email: {
                incorrect: '\u0057\u0072\u006f\u006e\u0067\u0020\u0061\u0064\u0064\u0072\u0065\u0073\u0073\u0020\u0066\u006f\u0072\u006d\u0061\u0074',
                symbolNotFound: '\u0054\u0068\u0065\u0020\u0061\u0064\u0064\u0072\u0065\u0073\u0073\u0020\u006d\u0075\u0073\u0074\u0020\u0063\u006f\u006e\u0074\u0061\u0069\u006e\u0020\u0074\u0068\u0065\u0020\u0063\u0068\u0061\u0072\u0061\u0063\u0074\u0065\u0072\u0020\u201c\u0040\u201c\u002e\u0020\u0054\u0068\u0065\u0020\u0061\u0064\u0064\u0072\u0065\u0073\u0073\u0020\u0069\u0073\u0020\u006d\u0069\u0073\u0073\u0069\u006e\u0067\u0020\u0074\u0068\u0065\u0020\u0073\u0079\u006d\u0062\u006f\u006c\u0020\u0022\u0040\u0022',
                addressIsNotFull: '\u0045\u006e\u0074\u0065\u0072\u0020\u0074\u0068\u0065\u0020\u0070\u0061\u0072\u0074\u0020\u006f\u0066\u0020\u0074\u0068\u0065\u0020\u0061\u0064\u0064\u0072\u0065\u0073\u0073\u0020\u0061\u0066\u0074\u0065\u0072\u0020\u0074\u0068\u0065\u0020\u201c\u0040\u201d\u0020\u0063\u0068\u0061\u0072\u0061\u0063\u0074\u0065\u0072\u002e\u0020\u0041\u0064\u0064\u0072\u0065\u0073\u0073\u0020\u0069\u0073\u0020\u006e\u006f\u0074\u0020\u0066\u0075\u006c\u006c\u002e'
            },
            password: {
                minLength: '\u0054\u0068\u0065\u0020\u006d\u0069\u006e\u0069\u006d\u0075\u006d\u0020\u006e\u0075\u006d\u0062\u0065\u0072\u0020\u006f\u0066\u0020\u0063\u0068\u0061\u0072\u0061\u0063\u0074\u0065\u0072\u0073\u0020\u0061\u006c\u006c\u006f\u0077\u0065\u0064\u0020\u0069\u0073 6. \u004e\u006f\u0077 %current_length%'
            },
            repeatPassword: {
                discrepancy: '\u0054\u0068\u0065\u0020\u0065\u006e\u0074\u0065\u0072\u0065\u0064\u0020\u0070\u0061\u0073\u0073\u0077\u006f\u0072\u0064\u0073\u0020\u0064\u006f\u006e\u0027\u0074\u0020\u006d\u0061\u0074\u0063\u0068'
            },
            ofertaAgreement: {
                notChecked: '\u0043\u006f\u006e\u0066\u0069\u0072\u006d\u0020\u0079\u006f\u0075\u0072\u0020\u0061\u0063\u0063\u0065\u0070\u0074\u0061\u006e\u0063\u0065\u0020\u006f\u0066\u0020\u0074\u0068\u0065\u0020\u0072\u0075\u006c\u0065\u0073'
            },
        },

        options: {
            regType: "phone",
            bonusType: "casino",

            tabButtonClass: ".tab",
            formEmailClass: ".form-email",
            formPhoneClass: ".form-phone",
            buttonActionClass: ".button-action",
            emailInputClass: ".email-input",
            passwordInputClass: ".password-input",
            passwordInput2Class: ".password-input2",
            phoneInputClass: ".phone-input",
            emailCountry: ".email-country",
            phonePrefixSelect: ".phone-prefix__select",
            phonePrefixCode: ".phone-prefix__code",
            phoneCurrency: ".phone-currency",
            emailCurrency: ".email-currency",
            phonePrefixFlag: ".phone-prefix__flag",

            emailErrorClass: ".email-error",
            passwordErrorClass: ".password-error",
            password2ErrorClass: ".password2-error",
            phoneErrorClass: ".phone-error",
            ofertaAgreementInputId: "#oferta-agreement",
        },
        
    });

    start({ phones: window.phones, translations: translations });
});